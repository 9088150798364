export default function RandomProfileImage(): string {
    const urlPrefix =
      'https://moneyline-profile-images.s3.us-west-1.amazonaws.com/';
  
    const images = [
      'default_baseball-alien.png',
      'default_baseball-dreamy.png',
      'default_baseball-fox.png',
      'default_baseball-grunge.png',
      'default_baseball-lizard.png',
      'default_baseball-trippy.png',
      'default_basketball-alien.png',
      'default_basketball-dreamy.png',
      'default_basketball-giraffe.png',
      'default_basketball-grunge.png',
      'default_basketball-leopard.png',
      'default_basketball-panda.png',
      'default_basketball-rabbit.png',
      'default_basketball-trippy.png',
      'default_football-alien.png',
      'default_football-gorilla.png',
      'default_football-grunge.png',
      'default_football-lion.png',
      'default_football-trippy.png',
      'default_hockey-alien.png',
      'default_hockey-dreamy.png',
      'default_hockey-penguin.png',
      'default_hockey-polar-bear.png',
      'default_hockey-shark.png',
      'default_hockey-trippy.png',
    ];
  
    return urlPrefix + images[Math.floor(Math.random() * images.length)];
  }
  